import React from 'react';

import AccountMenu from './menu';

import './sidebar.css';

import { SubtitlePlaceholder } from '../Subpage/placeholders';

import loadComponents from '../Loadable';
import Placeholder from '../Placeholder';

const Title = loadComponents('h2');
const Link = loadComponents('link');
const ButtonHidden = loadComponents('button-hidden');

export default function Sidebar(props) {
  const { logoutUser } = props;
  return (
    <aside>
      <div className="accountNavigation">
        <Title
          margin="2.8125rem"
          style={{ textTransform: `uppercase` }}
          fallback={<SubtitlePlaceholder />}
        >
          Navigation
        </Title>
        <ul className="accountLinks">
          {AccountMenu.map(item => (
            <li key={item.url}>
              <Link
                to={item.url}
                className="btnLink"
                content={item.label}
                fallback={<LinkPlaceholder />}
              />
            </li>
          ))}
          <li>
            <ButtonHidden
              onClick={logoutUser}
              style={{ lineHeight: 1.8 }}
              fallback={<LinkPlaceholder />}
            >
              Logout
            </ButtonHidden>
          </li>
        </ul>
      </div>
    </aside>
  );
}

const LinkPlaceholder = () => (
  <Placeholder
    height="24px"
    width="140px"
    colour="#000"
    style={{ marginBottom: `10px` }}
  />
);
