export default [
  {
    url: '/account/orders/',
    label: 'Orders',
  },
  // {
  //   url: '/account/wishlist/',
  //   label: 'Wishlist',
  // },
  // {
  //   url: "/account/messages",
  //   label: "Messages"
  // },
  {
    url: '/account/',
    label: 'Settings',
  },
];
